<template>
  <div>
    <v-app>
      <v-container>
        <div class="page-card">
          <v-row>
            <v-col md="12">
              <v-row>
                <v-col md="4">
                  <v-text-field
                    v-model="search"
                    append-icon="search"
                    label="Ara"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col md="2 ml-auto">
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="goAddUser()"
                  >
                    Ekle
                  </button>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="12">
                  <v-data-table
                    :headers="headers"
                    :items="desserts2"
                    :search="search"
                    :loading="loadings.table"
                    loading-text="Yükleniyor..."
                    :footer-props="{
                      'items-per-page-options': [20, 30, 40, -1],
                    }"
                    :items-per-page="20"
                  >
                    <template v-slot:[`item.fullName`]="{ item }">
                      <div>
                        {{ item.user.name + " " + item.user.surname }}
                      </div>
                    </template>
                    <template v-slot:[`item.edit`]="{ item }">
                      <v-icon class=" fa-pencil-blue" @click="goUserEdit(item)">
                        fas fa-pencil-alt
                      </v-icon>
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
                      <v-icon class=" text-warning" @click="goUserView(item)">
                        fas fa-chevron-circle-right
                      </v-icon>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-app>
  </div>
</template>

<style lang="scss">
//@import "@/assets/sass/pages/wizard/wizard-3.scss";
</style>

<script>
//import KTCodePreview from "@/view/content/CodePreview.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
//import KTUtil from "@/assets/js/components/util";
//import KTWizard from "@/assets/js/components/wizard";
//import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";
import { SET_SELECTEDPAGEITEMNAME } from "@/core/services/store/selectedpageitem.module";
//import {usersview} from "./UsersView.vue";
export default {
  name: "userlist",
  props: ["server"],
  data() {
    return {
      loadings: {
        table: false,
      },
      search: "",
      centers: [],
      desserts2: [
        {
          name: "",
          surname: "",
          username: "",
          title: "",
          //merkezorg:"" , // to do  2 servis cagrılacak
          specialtyDepartment: "",
          email: "",
          phoneNumber: "",
          country: "",
          city: "",
          detay: "",
          edit: "",
        },
      ],
      headers: [
        {
          text: "Ünvan",
          align: "left",
          value: "user.title",
          width: "120px",
        },
        { text: "Ad", value: "name", width: "100px" },
        //{ text: "Kullanıcı Adı", value: "username", width: "100px" },
        { text: "Soyad", value: "surname", width: "100px" },
        //{ text: "Ad Soyad", value: "fullName", width: "150px" },
        { text: "Merkez/Org", value: "user.center.centerName", width: "150px" },
        { text: "Bölüm", value: "user.specialtyDepartment", width: "100px" },
        { text: "E-posta", value: "email", width: "100px" },
        //{ text: "Telefon", value: "phoneNumber" },
        //{ text: "Ülke", value: "country" },
        //{ text: "İl", value: "city" },
        { text: "Düzenle", value: "edit", sortable: false, width: "100px" },
        { text: "Detay", value: "action", sortable: false, width: "100px" },
      ],
    };
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Kullanıcı Listesi", route: "/userlist" },
    ]);
    this.getUserlist();
  },
  methods: {
    goUserEdit(item) {
      localStorage.setItem("itemler", JSON.stringify(item));
      this.$store.dispatch(SET_SELECTEDPAGEITEMNAME, "Kullanıcı Listesi");
      this.$router.push({ name: "usersedit", params: { itemid: item.id } });
    },
    goUserView(item) {
      localStorage.setItem("itemler", JSON.stringify(item));
      this.$store.dispatch(SET_SELECTEDPAGEITEMNAME, "Kullanıcı Listesi");
      this.$router.push({ name: "usersview", params: { itemid: item.id } });
    },
    goAddUser() {
      this.$store.dispatch(SET_SELECTEDPAGEITEMNAME, "Kullanıcı Listesi");
      this.$router.push({ name: "users" });
    },
    test() {
      console.log("test");
    },
    getUserlist() {
      this.loadings.table = true;
      ApiService.get("/users", this.selected)
        .then(({ data }) => {
          console.log("user  list burada", data);
          this.desserts2 = data.result;
          //console.log("dessert", this.desserts2);
        })
        .catch((err) => {
          console.log("error", err);
        })
        .then(() => {
          this.loadings.table = false;
        });
    },
    getCurrentid(item) {
      // this.$emit('getCurrentid',item);
      //console.log("item", item.id);
      //usersview.getCurrentUserinfo(item.id);
      //localStorage.setItem("itemler",JSON.stringify(item));
      this.$router.push({ name: "usersview", params: { itemid: item.id } });
    },
    getCurrentid2(item) {
      // this.$emit('getCurrentid',item);
      // console.log("item", item.id);
      //usersview.getCurrentUserinfo(item.id);
      //localStorage.setItem("itemler",JSON.stringify(item));
      this.$router.push({ name: "usersedit", params: { itemid: item.id } });
    },
  },
  computed: {
    tab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } });
      },
      get() {
        console.log("query", this.$route.query.tab);
        return this.$route.query.tab;
      },
    },
  },
};
</script>
